import { Injectable } from "@angular/core";

@Injectable()
export class LoaderService {
  public showLoader: boolean;

  constructor() {
    this.showLoader = false;
  }

  show() {
    this.showLoader = true;
  }
  hide() {
    this.showLoader = false;
  }
}
