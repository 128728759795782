import { Component, OnInit } from '@angular/core';
import { DataFactory } from '../shared/models/dataFactory';
import { SharedDataService } from '../shared/services/shared-data.service';
import { DataService } from '../shared/services/data.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  public footerCountry="";

  constructor(public dataFactory: DataFactory, private _dataService: DataService) {
    
  }

  ngOnInit() {
    this.sendrestaurantisreaded();
  }

  private sendrestaurantisreaded(){
    console.log("readed");
    this._dataService.restoreaded.subscribe(x=>{
      if(SharedDataService.countryid == 2 && this.dataFactory.restaurant.countryId==1){
        this.footerCountry=", België";
      }
      else if(SharedDataService.countryid != 2 && this.dataFactory.restaurant.countryId==2){
        this.footerCountry=", Nederland";
      }
    })
  }
}
