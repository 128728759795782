import * as dayjs from 'dayjs';

export class Restaurant {
    public btw_nummer: string;
    public categories_subsite;
    public deliver_friday: string;
    public deliver_monday: string;
    public deliver_saturday: string;
    public deliver_sunday: string;
    public deliver_thursday: string;
    public deliver_tuesday: string;
    public deliver_wednesday: string;
    public id: string;
    public menus_subsite;
    public note_public: string;
    public open_friday: string;
    public open_monday: string;
    public open_saturday: string;
    public open_sunday: string;
    public open_thursday: string;
    public open_tuesday: string;
    public open_wednesday: string;
    public restaurant_address: string;
    public restaurant_city: string;
    public restaurant_description: string;
    public restaurant_logo: string;
    public restaurant_name: string;
    public restaurant_postcode: string;
    public restaurant_slug: string;
    public restaurant_tel: string;
    public review_avg_star_cnt: number;
    public review_avg_star_pro: number;
    public review_total_cnt: number;
    public reviews_subsite;
    public slogan: string;
    public types_subsite;
    public denemeopen2: string;
    public lang: string;

    public typeImageUrl: string = "";
    public lat: number = null;
    public long: number = null;

    //generated in the ts-file
    public isOpenToday: number;
    public isDeliveringToday: number;
    public onlyonlinepay: boolean;

    public calc_title: string="";
    public servertime: string=dayjs().format("YYYY-MM-DD HH:mm:ss"); //this is how it comes from server 
    public countryId: number;
    //public servertime: string="";
}