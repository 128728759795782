import { TranslateService } from '@ngx-translate/core';
import {map} from 'rxjs/operators';
import { HttpXService } from "./httpx.service";
import { Injectable, EventEmitter } from "@angular/core";

import { OrderForm } from "../models/orderForm.model";
import { PlaceOrder } from "../models/placeOrder.model";
import { DataFactory } from "../models/dataFactory";
import { environment } from "../../../environments/environment";
import { HttpHeaders } from '@angular/common/http';
import { SharedDataService } from './shared-data.service';

const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Accept': 'application/json'
    })
  };

@Injectable()
export class DataService {

    public googleAnalyticsEventEmitter: EventEmitter<string> = new EventEmitter();

    public restoreaded: EventEmitter<void> = new EventEmitter();

    constructor(private http: HttpXService, private _dataFactory: DataFactory, private _translateService: TranslateService) {
    }

    public sendGoogleAnalyticsEvent(category: string, action: string, label: string, value: number){
        const gaEvent = {category: category, action: action, label: label, value: value};
        this.googleAnalyticsEventEmitter.emit(JSON.stringify(gaEvent));
      }

    public placeOrder(newOrder: OrderForm): any {
        const endpoint = 'xHttp/';
        const placeOrder: PlaceOrder = new PlaceOrder();

        placeOrder.mapOrderFormModelToPlaceOrderModel(newOrder, this._dataFactory.restaurant.id, this._dataFactory.orderGuid, this._dataFactory.restaurant.restaurant_slug, this._translateService.currentLang, this._dataFactory.restaurant.lang);

        return this.http.postOptions(SharedDataService.checkouturl + endpoint, placeOrder, httpOptions).pipe(
            map((res: any) => res));
    }

    public getRestaurantInfo(restid: string): any {


        return this.http.getOptions(SharedDataService.baseurl+'xHttp/subweb?id=' + restid + '&less=true', httpOptions)
        .pipe(map((res: any) => res));
    }

    // public getPaymentMethods(): any {
    //     const url = environment.BASE_URL + 'xHttp/showPaymentMethods';

    //     return this.http.post(url, this.getRequestOptions()).pipe(
    //         map((res: Response) => res.json()));
    // }

    public getOrderMetaData(guid: string, paymentid: string, payerid: string): any {
        const endpoint = 'xHttp/';

        const requestbody = {
            "action": "readinfo",
            "guid": guid,
            "paymentid": paymentid,
            "payerid": payerid
        }

        return this.http.postOptions(SharedDataService.checkouturl + endpoint, requestbody, httpOptions)
        .pipe(map((res: any) => res));
    }

    public getOrderDetails(guid: string): any {
        const endpoint = 'xHttp/';

        const requestbody = {
            "action": "read",
            "guid": guid
        }

        return this.http.postOptions(SharedDataService.checkouturl + endpoint, requestbody, httpOptions).pipe(
            map((res: any) => res));
    }

    // private getRequestOptions(): RequestOptions {
    //     const headers = new Headers();
    //     headers.append('Content-Type', 'application/json');
    //     headers.append('Accept', 'application/json');

    //     return new RequestOptions({ headers: headers });
    // }

    public getCoordinates(street: string, no: string, zip: string, city: string): any {
        const url: string = "https://maps.googleapis.com/maps/api/geocode/json?address=" + no + "+" + street + "+" + zip + "+" + city + "+"+(SharedDataService.countryid==2?"nederland":"belgie")+"&key=" + environment.API_KEY;

        return this.http.get(url).pipe(
            map((res: any) => res));
    }

}