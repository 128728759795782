import { Component } from '@angular/core';
import { LoaderService } from './shared/services/loader.service';
import { DataService } from './shared/services/data.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { SharedDataService } from './shared/services/shared-data.service';
import { Meta } from '@angular/platform-browser';
import { setTheme } from 'ngx-bootstrap/utils';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';
  public isLoading: boolean = this._loaderService.showLoader;

  constructor(private _loaderService: LoaderService, private _dataService: DataService, private _translateService: TranslateService, private _meta: Meta){
    setTheme('bs5'); // or 'bs4'
    _translateService.setDefaultLang('nl');
    
    if(window.location.origin.endsWith('.nl')){
      SharedDataService.countryid = 2;
      SharedDataService.baseurl = environment.BASE_URL_NL;
      SharedDataService.checkouturl = environment.CHECKOUT_URL_NL;

      this._meta.removeTag('name="apple-itunes-app"');
      this._meta.removeTag('name="google-play-app"');
    }
    else{
      SharedDataService.countryid = 1;
      SharedDataService.baseurl = environment.BASE_URL;
      SharedDataService.checkouturl = environment.CHECKOUT_URL;
    }


  }

  ngOnInit(){
    this.sendGoogleAnalyticsEvent();
  }

  private sendGoogleAnalyticsEvent(){
    this._dataService.googleAnalyticsEventEmitter.subscribe(res=>{
      const gaEvent = JSON.parse(res);
      if (+(gaEvent.value) == -1){
        gaEvent.value = undefined;
      }
      //console.log(gaEvent);
      try
      {
      (<any>window).dataLayer.push({'event':'gaevent','category':gaEvent.category,'action':gaEvent.action,'label':gaEvent.label,'value':gaEvent.value});
      }
      catch(Error)
      {
        console.log(Error.message);
      }
      //(<any>window).ga('send', 'event', gaEvent.category,gaEvent.action,gaEvent.label,gaEvent.value);
    })
  }
}
