import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { LoaderComponent } from './shared/loader/loader.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { AppRouting } from './app.routes';
import { LoaderService } from './shared/services/loader.service';
import { HomeComponent } from './home/home.component';
import { DataService } from './shared/services/data.service';
import { HttpXService } from './shared/services/httpx.service';

import { DataFactory } from './shared/models/dataFactory';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CookieService } from 'ngx-cookie-service';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient, HttpClientModule, HttpHandler} from '@angular/common/http';
import { TitleCasePipe  } from '@angular/common';
import { SharedDataService } from './shared/services/shared-data.service';



@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent,
    HeaderComponent,
    FooterComponent,
    CheckoutComponent,
    HomeComponent
  ],
  imports: [
    BrowserModule,
    AppRouting,
    FormsModule,
    HttpClientModule,
    ModalModule.forRoot(),
    HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })
  ],
  providers: [
    SharedDataService,
    DataService,
    DataFactory,
    LoaderService,
    {
      provide: HttpXService,
      useFactory: createHttpXLoader,
      deps: [
        HttpHandler,
        LoaderService
      ]
    },
    CookieService,
    TitleCasePipe,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function createHttpXLoader(options: HttpHandler, loaderService: LoaderService) {
  return new HttpXService(options, loaderService);
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}