<footer id="footer">
  <div class="container">
    <div class="row">
      <div class="col-sm-6 col-md-6 openhours-wrapper">
        <h3>
          <i class="icon-clock"></i> {{'footer.openingsuren-titel'| translate }}</h3>
        <div class="openhours">
          <div class="days-block">
            {{'footer.maandag-label'| translate }}
            <br> {{'footer.dinsdag-label'| translate }}
            <br> {{'footer.woensdag-label'| translate }}
            <br> {{'footer.donderdag-label'| translate }}
            <br> {{'footer.vrijdag-label'| translate }}
            <br> {{'footer.zaterdag-label'| translate }}
            <br> {{'footer.zondag-label'| translate }}
          </div>
          <div class="hours-block">
            {{dataFactory.restaurant.open_monday}}
            <br> {{dataFactory.restaurant.open_tuesday}}
            <br> {{dataFactory.restaurant.open_wednesday}}
            <br> {{dataFactory.restaurant.open_thursday}}
            <br> {{dataFactory.restaurant.open_friday}}
            <br> {{dataFactory.restaurant.open_saturday}}
            <br> {{dataFactory.restaurant.open_sunday}}
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-6">
        <h3>
          <i class="icon-location"></i>{{'footer.adres-label'| translate }}</h3>
        <b>{{dataFactory.restaurant.restaurant_name}}</b>
        <br> {{dataFactory.restaurant.restaurant_address}},
        <br> {{dataFactory.restaurant.restaurant_postcode}} {{dataFactory.restaurant.restaurant_city}} {{footerCountry}}
        <br><br> {{'footer.tel-label'| translate }} {{dataFactory.restaurant.restaurant_tel}}
      </div>
    </div>
  </div>
</footer>