import { ModuleWithProviders } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { CheckoutComponent } from "./checkout/checkout.component";
import { HomeComponent } from "./home/home.component";

export const appRoutes: Routes = [
    { path: 'order/:id', component: CheckoutComponent },
    //{ path: 'besteld-bij/:resname/:id', component: ThankYouComponent },
    { path: '**', component: HomeComponent },
    { path: '', component: HomeComponent }
];

export const AppRouting: ModuleWithProviders<RouterModule> = RouterModule.forRoot(appRoutes);
