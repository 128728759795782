import { EventEmitter, Injectable } from '@angular/core';


@Injectable()
export class SharedDataService {

    public static countryid: number;
    public static baseurl: string ='';
    public static checkouturl: string ='';


    constructor() { 


    }






}
