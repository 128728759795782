import { OrderForm } from "./orderForm.model";
import { BasketList, BasketListChild } from "./basketlList.model";
import { SharedDataService } from "../services/shared-data.service";

export class PlaceOrder {
    public payswith: string = "";
    public payscache: number;
    public typagereferrer: string = "";
    public curpagereferrer: string = "";
    public data: PlaceOrderDataItem[];
    public deliveryprice: string = "";
    public rest_id: string = "";
    public totalprice: number;
    public zipcode: string = "";
    public subtotal: number;
    public pickup: number; // 0: false | 1: true
    public action: string = "placeorder";
    public email: string = "";
    public phonenumber: string = "";
    public deliverytime: string = "";
    public address: string = "";
    public postcode: string = "";
    public town: string = "";
    public notes: string = "";
    public companyname: string = "";
    public distance: string = "";
    public paymentcardcosts: string = "";
    public name: string = "";
    public referrer: string = "";
    public guid: string = "";
    public checkoutreferrer:string="";
public browser: string = "";
public subtotal_before: number;
public discountP: number = 0;
public discountC: number = 0;
public lang: string = "nl";

    public mapOrderFormModelToPlaceOrderModel(order: OrderForm, restaurant_id: string, orderGuid: string, rest_slug: string, lang: string, restlang: string) {
        this.payswith = order.paymentMethod;
        this.typagereferrer = "";
        this.data = [];
        this.deliveryprice = order.deliveryPrice.toString();
        this.name = order.buyerName;
        this.rest_id = restaurant_id;
        this.totalprice = order.totalPrice;
        this.zipcode = order.deliverZip;
        this.subtotal = order.subtotalPrice;
        this.pickup = order.isPickup ? 1 : 0;
        this.email = order.buyerEmail;
        this.phonenumber = order.buyerTel;
        this.deliverytime = order.deliveryHour;
        this.address = order.deliverAddress;
        this.postcode = order.deliverZip;
        this.town = order.deliverCity;
        this.notes = order.orderNotes;
        this.companyname = order.buyerOrg;
        this.distance = order.distance;
        this.paymentcardcosts = order.transactionPrice.toString();
        this.payscache = order.contantPaymentAmount;
        this.curpagereferrer = SharedDataService.checkouturl + "order/" + orderGuid;
        //this.typagereferrer = "https://checkout.etenonline.be/besteld-bij/" + rest_slug + "/" + orderGuid;
        this.lang = lang;



        let ttt = "besteld-bij/";

        if(lang==restlang){
            if(lang=="en"){
                ttt="ordered-at/";
                //this.typagereferrer = order.checkoutreferrer + "ordered-at/" + rest_slug + "/" + orderGuid;
            }
            else if(lang=="fr"){
                ttt="commande-aupres-de/";
                //this.typagereferrer = order.checkoutreferrer + "commande-aupres-de/" + rest_slug + "/" + orderGuid;
            }
            // else{
            //     this.typagereferrer = order.checkoutreferrer + "besteld-bij/" + rest_slug + "/" + orderGuid;
            // }
        }
        else {
            if(lang=="en"){
                ttt="en/ordered-at/";
                //this.typagereferrer = order.checkoutreferrer + "en/ordered-at/" + rest_slug + "/" + orderGuid;
            }
            else if(lang=="fr"){
                ttt="fr/commande-aupres-de/";
                //this.typagereferrer = order.checkoutreferrer + "fr/commande-aupres-de/" + rest_slug + "/" + orderGuid;
            }
            else {
                ttt="nl/besteld-bij/";
                //this.typagereferrer = order.checkoutreferrer + "nl/besteld-bij/" + rest_slug + "/" + orderGuid;
            }
        }

        this.typagereferrer = order.checkoutreferrer + ttt + rest_slug + "/" + orderGuid;

        this.referrer = order.referrer;
        this.guid = orderGuid;
        this.checkoutreferrer = order.checkoutreferrer;
        this.browser = order.browser;
        this.subtotal_before = order.subtotal_before;
        this.discountP = order.discountP;
        this.discountC = order.discountC;

        if (order.productList != null) {
            order.productList.forEach(element => {
                this.data.push({ ...this.mapBasketListToPlaceOrderData(element) });
            });
        }




    }

    private mapBasketListToPlaceOrderData(basketList: BasketList): PlaceOrderDataItem {
        let placeOrderDataItem: PlaceOrderDataItem = new PlaceOrderDataItem();

        placeOrderDataItem.custom_id = basketList.menu_custom_id;
        placeOrderDataItem.childs = [];
        placeOrderDataItem.menu_id = basketList.menu_id;
        placeOrderDataItem.price = basketList.menu_price;
        placeOrderDataItem.toPrice = basketList.to_menu_price;
        placeOrderDataItem.qty = basketList.qty;
        placeOrderDataItem.menu_id_exact = +(basketList.menu_id_exact);
        placeOrderDataItem.name = basketList.menu_name;
        placeOrderDataItem.menucat = basketList.menucat;
        placeOrderDataItem.menucat_name = basketList.menucat_name;

        if (basketList.childs != null) {
            basketList.childs.forEach(element => {
                placeOrderDataItem.childs.push({ ...this.mapBasketListChildToDataChildItem(element) });
            });
        }


        return placeOrderDataItem;
    }

    private mapBasketListChildToDataChildItem(basketListChild: BasketListChild): PlaceOrderDataChildItem {
        let placeOrderDataChildItem: PlaceOrderDataChildItem = new PlaceOrderDataChildItem();

        placeOrderDataChildItem.id = basketListChild.child_id;
        placeOrderDataChildItem.name = basketListChild.child_name;
        placeOrderDataChildItem.price = basketListChild.child_price;

        return placeOrderDataChildItem;
    }
}

export class PlaceOrderDataItem {
    public custom_id: string;
    public childs: PlaceOrderDataChildItem[];
    public menu_id: string;
    public price: string;
    public toPrice: string;
    public qty: string;
    public menu_id_exact: number;
    public name: string;
    public menucat: number;
    public menucat_name: string;
}

export class PlaceOrderDataChildItem {
    public id: string;
    public name: string;
    public price: string;
}