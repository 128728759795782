import { Restaurant } from "./restaurant.model";
import { DeliverZipCode } from "./deliverZipCode.model";
import { PaymentMethod } from "./paymentMethods.model";
import { Injectable } from "@angular/core";

@Injectable()
export class DataFactory {
    public restaurant: Restaurant = new Restaurant();
    public deliverZipCodes: DeliverZipCode[] = [];
    public checkoutreferrer: string = "";

    public paymentMethods: PaymentMethod[] = [];
    
    public orderGuid: string;

    public avaiblelangs: string[]=["nl","fr","en"];


    public setRestaurantDefaultData(id: string, name: string, slogan: string, logo: string, img_sub: string){
        this.restaurant.restaurant_name = name;
        this.restaurant.slogan = slogan;
        this.restaurant.id = id;
        this.restaurant.restaurant_logo = logo;
        this.restaurant.typeImageUrl = img_sub;
    }


    

    //setRestaurantOpeningHours - monday until sunday
}