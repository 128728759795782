export class DeliverZipCode {
    public id: number;
    public pivot: PivotModel;
    public zcCity: string = "";
    public zcCountryCode: string = "";
    public zcLat: number;
    public zcLng: number;
    public zcMainCity: string = ""
    public zcZip: any = "";
}

export class PivotModel {
    public delivery_charge: number;
    public free_above: string;
    public minimum_order: string;
    public rId: number;
    public zcId: number;
}
