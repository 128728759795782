import { OrderForm } from "./orderForm.model";

export class UserFormStorage {
    public address: string = "";
    public zip: string = "";
    public city: string = "";
    public name: string = "";
    public email: string = "";
    public tel: string = "";
    public org: string = "";
    public notes: string = "";
    public savenotes: boolean=false;

    public mapOrderFormToUserFormStorage(order: OrderForm){
        this.address = order.deliverAddress;
        this.zip = order.deliverZip;
        this.city = order.deliverCity;
        this.name = order.buyerName;
        this.email = order.buyerEmail;
        this.tel = order.buyerTel;
        this.org = order.buyerOrg;
        this.notes = order.orderNotes;
        this.savenotes = order.saveNotes;
    }
}