// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  BASE_URL: 'https://dev.menute.be/',
  BASE_URL_NL: 'https://dev.menute.nl/',
  CHECKOUT_URL:"https://devcheckout.menute.be/",
  CHECKOUT_URL_NL:"https://devcheckout.menute.nl/",
  production: false,
  ZIP_CODE_PATTERN: "^[1-9]{1}[0-9]{3}$",
  API_KEY: 'AIzaSyDpoWh7K4MhE9MM2YwRGbbAsENTD8NcOKw',
  EMAIL_PATTERN: "^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$",
  CDN_URL:"https://cdn.etenonline.be/",
};
