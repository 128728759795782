
import {throwError as observableThrowError } from 'rxjs';

import {finalize, catchError} from 'rxjs/operators';
import { LoaderService } from "./loader.service";
import { Injectable } from "@angular/core";
import { HttpClient,HttpHandler } from '@angular/common/http';


@Injectable()
export class HttpXService extends HttpClient {
    constructor(
        options: HttpHandler,
        public loaderService: LoaderService
    ) {
        super(options);
    }

    // post(url, requestBody) {
    //     this.showLoader();

    //     return super
    //         .post(url, requestBody).pipe(
    //         catchError(this.handleError),
    //         finalize(() => this.hideLoader()),);
    // }

    postOptions(url:string, requestBody, options) {
        this.showLoader();

        return super
            .post(url, requestBody, options)
            .pipe(
                catchError(this.handleError),
                finalize(() => {this.hideLoader()})
            )
    }

    getOptions(url:string, options) {
        this.showLoader();

        return super
            .get<any>(url, options)
            .pipe(
                catchError(this.handleError),
                finalize(() => {this.hideLoader()})
            );
    }

    get(url) {
        this.showLoader();

        return super
            .get<any>(url).pipe(
            catchError(this.handleError),
            finalize(() => {this.hideLoader()})
            )
    }

    // delete(url, options) {
    //     this.showLoader();

    //     return super
    //         .delete<any>(url, options).pipe(
    //         catchError(this.handleError),
    //         finalize(() => this.hideLoader()),);
    // }

    private showLoader(): void {
        this.loaderService.show();
    }

    private hideLoader(): void {
        this.loaderService.hide();
    }

    // ServiceBase
    private handleError(response: Response) {
        if (response.status === 0) {
            // console.log(
            //     "*** ServiceBase: Connection issue with Server ... Status Code:" +
            //     response.status
            // );
            return observableThrowError("[=error.message.connection.to.server]");
        }
        if (response.status === 401) {
            //console.log("*** ServiceBase: You are not autorized" + response.status);
            return observableThrowError("[=error.message.not.autorized]");
        }
        return observableThrowError(
            "[=error.message.statuscode." + response.status + "]"
        );
    }
}
