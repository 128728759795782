<nav class="navbar navbar-expand-md navbar-light container px-2" *ngIf="showMenu">


    <div class="fcontainer">
        <div class="fbox fbox1"><span><button role="button" aria-label="Menu" class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navigation" (click)="toggleState()">
            <span class="navbar-toggler-icon"></span>
          </button></span></div>
        <div class="fbox fbox2"><span><h1 class="headertitle">{{dataFactory.restaurant.calc_title}}</h1></span></div>
        <div class="fbox fbox3"><span><a class="navbar-brand">
            <img [src]="'https://cdn.etenonline.be/upload/restaurants/' + dataFactory.restaurant.restaurant_logo + '-b.png'" alt="restaurant image">
          </a></span></div>
      </div>


  <div class="collapse navbar-collapse justify-content-end" id="navigation" *ngIf="showMenu" [ngClass]="{ 'show': isIn }">
    <ul class="navbar-nav ">
      <li class="nav-item">
        <a class="nav-link"  (click)="goToMenu()">{{'header.bestellen-titel' | translate}}</a>
      </li>
      <li *ngIf="dataFactory.restaurant.review_total_cnt>0" class="nav-item">
      <a class="nav-link"  (click)="goToReviews()">{{'header.beoordelingen-titel' | translate}}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" (click)="goToContact()">{{'header.contact-titel' | translate}}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" (click)="useLanguage(dataFactory.restaurant.lang)" [style.font-weight]="translateService.currentLang==dataFactory.restaurant.lang ? '900' : 'normal'">{{dataFactory.restaurant.lang | uppercase }}</a>
      </li>
      <ng-template ngFor let-item [ngForOf]="dataFactory.avaiblelangs">
        <li class="nav-item" *ngIf="item!=dataFactory.restaurant.lang">
          <a class="nav-link" (click)="useLanguage(item)" [style.font-weight]="translateService.currentLang==item ? '900' : 'normal'">{{item | uppercase }}</a>
        </li>
      </ng-template>
    </ul>
  </div>









</nav>

<div class="navbar navbar-expand-md navbar-light container" *ngIf="!showMenu">
  <span class="navbar-brand m-auto">
    <img *ngIf="dataFactory.restaurant.restaurant_logo != null" [src]="dataFactory.restaurant.restaurant_logo" alt="restaurant image">
  </span>
</div>