import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, Input } from '@angular/core';
import { DataFactory } from '../shared/models/dataFactory';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

export class HeaderComponent implements OnInit {


  @Input('showMenu') showMenu: boolean = true;
  constructor(public dataFactory: DataFactory, public translateService: TranslateService) { 
  }

  ngOnInit() { 
  }

  goToMenu(){



    if(this.dataFactory.restaurant.lang==this.translateService.currentLang){
      window.location.href = this.dataFactory.checkoutreferrer;
    }
    else{
      window.location.href = this.dataFactory.checkoutreferrer+this.translateService.currentLang;
    }


    
  }

  goToReviews(){

    if(this.dataFactory.restaurant.lang==this.translateService.currentLang){

      if(this.translateService.currentLang=="en"){
        window.location.href = this.dataFactory.checkoutreferrer+"reviews";
      }
      else if(this.translateService.currentLang=="nl"){
        window.location.href = this.dataFactory.checkoutreferrer+"beoordelingen";
      }
      else if(this.translateService.currentLang=="fr"){
        window.location.href = this.dataFactory.checkoutreferrer+"avis";
      }

    }
    else{

      if(this.translateService.currentLang=="en"){
        window.location.href = this.dataFactory.checkoutreferrer+"en/reviews";
      }
      else if(this.translateService.currentLang=="nl"){
        window.location.href = this.dataFactory.checkoutreferrer+"nl/beoordelingen";
      }
      else if(this.translateService.currentLang=="fr"){
        window.location.href = this.dataFactory.checkoutreferrer+"fr/avis";
      }

    }

  }

  goToContact(){
    if(this.dataFactory.restaurant.lang==this.translateService.currentLang){
      window.location.href = this.dataFactory.checkoutreferrer+"contact";
    }
    else{
      window.location.href = this.dataFactory.checkoutreferrer+this.translateService.currentLang+"/contact";
    }
  }

  isIn = false;   // store state
  toggleState() { // click handler
      let bool = this.isIn;
      this.isIn = bool === false ? true : false; 
  }


  public useLanguage(language: string) {
    this.translateService.use(language);
  }

}
