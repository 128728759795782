import { Component, OnInit } from '@angular/core';
import { OrderForm } from '../shared/models/orderForm.model';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../shared/services/data.service';
import * as dayjs from 'dayjs';
import 'dayjs/locale/nl-be';
import * as isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
declare var google;
import { BasketListChild } from '../shared/models/basketlList.model';
import { DataFactory } from '../shared/models/dataFactory';
import { OrderData } from '../shared/models/order.model';
import { DeliverZipCode } from '../shared/models/deliverZipCode.model';
import { PaymentMethod } from '../shared/models/paymentMethods.model';
import { DecimalPipe, TitleCasePipe } from '@angular/common';
import { UserFormStorage } from '../shared/models/userFormStorage.model';
import { LoaderService } from '../shared/services/loader.service';
import { Title } from '../../../node_modules/@angular/platform-browser';
import { BsModalService } from 'ngx-bootstrap/modal';
import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../environments/environment';
import { SharedDataService } from '../shared/services/shared-data.service';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements OnInit {
  public distinctDeliverZipCodes: DeliverZipCode[] = [];

  public estimatedDeliverTimes: Array<string> = [];

  public newOrder: OrderForm = new OrderForm;

  public selectedPaymentMethod: PaymentMethod;

  public betaalmogelijkheden: number[] = [];

  public transactionCostText: string = "";

  public errorMessages: string[] = [];

  public callInterval: any;

  public showAsapDeliverMethod: boolean = true;

  public basketErrorMessage: string = "";

  public erroraddressnumber: boolean = false;


  closeResult: string;

  constructor(private _titleService: Title, private _ActivatedRoute: ActivatedRoute, private _dataService: DataService, public dataFactory: DataFactory, private _router: Router,
     private _loaderService: LoaderService, private modalService: BsModalService,  private _cookieService: CookieService, private tcp:TitleCasePipe, private _translateService: TranslateService) { }

  ngOnInit() {
    this.callInterval = setInterval(() => { this._loaderService.showLoader = true }, 10);

    this.subscribeToGetProductById();
    // this.subscribeToGetAllPaymentMethods();

    let userformtest = this._cookieService.get('userform');

    if (userformtest) {
      let userform: UserFormStorage = JSON.parse(userformtest);

      this.newOrder.buyerEmail = userform.email;
      this.newOrder.buyerName = userform.name;
      this.newOrder.buyerTel = userform.tel;
      this.newOrder.deliverAddress = userform.address;
      this.newOrder.deliverCity = userform.city;
      this.newOrder.saveNotes = userform.savenotes;
      if (userform.savenotes) {
        this.newOrder.orderNotes = userform.notes;
      }
      this.newOrder.deliverZip = userform.zip;
      this.newOrder.buyerOrg = userform.org;
    }


    // if (localStorage.getItem('userform') != null) {
    //   let userform: UserFormStorage = JSON.parse(localStorage.getItem('userform'));
    //   this.newOrder.buyerEmail = userform.email;
    //   this.newOrder.buyerName = userform.name;
    //   this.newOrder.buyerTel = userform.tel;
    //   this.newOrder.deliverAddress = userform.address;
    //   this.newOrder.deliverCity = userform.city;
    //   this.newOrder.orderNotes = userform.notes;
    //   this.newOrder.deliverZip = userform.zip;
    //   this.newOrder.buyerOrg = userform.org;
    // }
  }

  open(content) {
    this.modalService.show(content, { ariaLabelledBy: 'modal-basic-title' });
  }

  close(){
    this.modalService.hide();
  }


  private scrollto() {
    let el = null;

    if (!this.newOrder.isPickup) {
      if (this.newOrder.deliverAddress == null || this.newOrder.deliverAddress.length == 0) {
        el = document.getElementById("iAddress");
        el.focus();
        return;

      }
      else if (this.newOrder.deliverCity == null || this.newOrder.deliverCity.length == 0) {
        el = document.getElementById("iPlaats");
        el.focus();
        el = document.getElementById("iAddress");
      }
    }

    if (el == null) {
      if (this.newOrder.buyerName == null || this.newOrder.buyerName.length == 0) {
        el = document.getElementById("iname");
        el.focus();

        if (this.newOrder.isPickup) {

          return;
        }
        else {
          el = document.getElementById("iAddress");
        }


      }
      else if (this.newOrder.buyerEmail == null || this.newOrder.buyerEmail.length == 0) {
        el = document.getElementById("iemail");
        el.focus();
        el = document.getElementById("iname");
      }
      else if (this.newOrder.buyerTel == null || this.newOrder.buyerTel.length == 0) {
        el = document.getElementById("itel");
        el.focus();
        el = document.getElementById("iemail");
      }
      else if (this.newOrder.deliveryHour == null || this.newOrder.deliveryHour == "select") {
        el = document.getElementById("icomment");
      }
      else {
        el = document.getElementById("icomment");
      }
    }

    if (el != null) {
      el.scrollIntoView();
    }
  }

  public changePaymentMethod(paymentMethod: PaymentMethod) {
    if (paymentMethod.pmStrId == "CNT") {
      this.newOrder.contantPaymentAmount = 0;
    } else {
      this.newOrder.contantPaymentAmount = -1;
    }

    this.selectedPaymentMethod = paymentMethod;
    this.getCustomPaymentInfo();
    this.setTransactionCost();
  }

  public setTransactionCost() {
    this.newOrder.totalPrice = +(this.newOrder.subtotalPrice) + +(this.newOrder.deliveryPrice) + this.newOrder.transactionPrice;
  }

  public fillDeliveryTimeArray() {
    // check if restaurant is open or not

    if (this.dataFactory.restaurant.isOpenToday == 0) {
      this.errorMessages = [];
      this._translateService.get('checkout.closed-today-label').subscribe((translated: string) => {
        this.errorMessages.push(translated);
      });
      return;
    }

    this.estimatedDeliverTimes = [];
    dayjs.locale('nl-be');
    dayjs.extend(isSameOrBefore)
    const now = dayjs(this.dataFactory.restaurant.servertime,"YYYY-MM-DD HH:mm:ss").second(0);
    let hours_today = [];
    const yesterday = dayjs(this.dataFactory.restaurant.servertime,"YYYY-MM-DD HH:mm:ss").second(0).subtract(1, 'day');
    let hours_yesterday = [];
    let yesterdayFlag: boolean = false;

    if (this.newOrder.isPickup) {

      this.getOpeningsOrDeliveryHoursOfDay(hours_today, now);
      this.getOpeningsOrDeliveryHoursOfDay(hours_yesterday, yesterday)
    } else {
      // console.log("delivery");
      this.getOpeningsOrDeliveryHoursOfDay(hours_today, now,false);
      this.getOpeningsOrDeliveryHoursOfDay(hours_yesterday, yesterday,false)
    }

    // console.log("hours of today")
    // console.log(hours_today);
    // console.log("hours of yesterday");
    // console.log(hours_yesterday);

//bug if resto is closed yesterday so its returned and what if today open ???
    // if (hours_yesterday.length <= 0) {
    //   // gisteren gesloten
    //   return;
    // } else {


      // if(hours_today.length<=0 && hours_yesterday.length<=0){
      //   return;
      // }


      if(hours_yesterday.length>0){
      if (hours_yesterday.length % 2 == 0) {

        for (let index = 0; index < hours_yesterday.length; index = index + 2) {
          // console.log("FOREACH FOR YESTERDAY FLAG")
          // console.log(index);
          // console.log(hours_yesterday[index]);
          // console.log(now);
          // console.log(hours_yesterday[index + 1]);
          // console.log(hours_yesterday[index] <= now);
          // console.log(now <= hours_yesterday[index + 1])
          if (hours_yesterday[index] <= now && now <= hours_yesterday[index + 1]) {
            // console.log("yesterdag flag is set true")
            yesterdayFlag = true;
            break;
          }
        }
      } else {
        // er is iets mis
      }
    }


    let hoursToDisplay = [];
    if (yesterdayFlag) {
      hoursToDisplay = hours_yesterday;
    } else {
      hoursToDisplay = hours_today;
    }

    // console.log("uren voor display"); console.log(hoursToDisplay)
    let firstPossibleTime = null;

    let deliveryTime = now;

    if (deliveryTime.minute() % 15 != 0) {
      const diff: number = 15 - (deliveryTime.minute() % 15);
      deliveryTime = deliveryTime.add(diff, 'm');
    }

    if(this.newOrder.isPickup) {
      deliveryTime = deliveryTime.add(15, 'm');
    } else {
      deliveryTime = deliveryTime.add(30, 'm');
    }

    for (let index = 0; index < hoursToDisplay.length; index = index + 2) {
      let minHour = hoursToDisplay[index];
      let maxHour = hoursToDisplay[index + 1];

      if (this.dataFactory.restaurant.isOpenToday == 2) {
        if(this.newOrder.isPickup) {
          minHour = minHour.add(15, 'm');
        } else {
          minHour = minHour.add(30, 'm');
        }
      }


      while (deliveryTime.isSameOrBefore(maxHour) || maxHour.format('HH:mm') == deliveryTime.format('HH:mm')) {
        if (minHour.isSameOrBefore(deliveryTime) || minHour.format('HH:mm') == deliveryTime.format('HH:mm')) {
          if (firstPossibleTime == null) {
            firstPossibleTime = deliveryTime;
          }
          this.estimatedDeliverTimes.push(deliveryTime.format('HH:mm'));
        }
        deliveryTime = deliveryTime.add(15, 'm');
      }
    }

    if (this.dataFactory.restaurant.isOpenToday == 2) {
      if (this.estimatedDeliverTimes.length > 0) {
        this.newOrder.deliveryHour = this.estimatedDeliverTimes[0];
      }
      // console.log("asap visible is false -- restaurant will open in future")
      this.showAsapDeliverMethod = false;
      this.newOrder.deliveryHour = "select";
    } else {
      if (this.dataFactory.restaurant.isOpenToday == 1) {
        // &&  firstPossibleTime >= minHour
        if ((!this.newOrder.isPickup) && (this.dataFactory.restaurant.isDeliveringToday == 2)) {
          this.showAsapDeliverMethod = false;
          this.newOrder.deliveryHour = "select";
        }
        else {

          this.showAsapDeliverMethod = true;
          this.newOrder.deliveryHour = "asap";

        }
        // let minHourString: string = minHour.format('HH').toString() + ":" + minHour.format('mm').toString();
        // if (minHourString.toLowerCase() == this.estimatedDeliverTimes[0].toLowerCase()) {
        //   this.showAsapDeliverMethod = true;
        // } else {
        //   this.showAsapDeliverMethod = false;
        // }
      } else {
        // console.log("asap visible is false")
        this.showAsapDeliverMethod = false;
        this.newOrder.deliveryHour = "select";
      }

    }


    // console.log("METHODE 2:");
    // console.log(this.estimatedDeliverTimes);
  }

  
  private getOpeningsOrDeliveryHoursOfDay(hours, now, takeout:boolean=true) {


    let currentdaytimes = takeout?this.dataFactory.restaurant.open_sunday:this.dataFactory.restaurant.deliver_sunday;

    switch (now.day()) {
      case 1:
        currentdaytimes = takeout?this.dataFactory.restaurant.open_monday:this.dataFactory.restaurant.deliver_monday;
      break;
        
      case 2:
        currentdaytimes = takeout?this.dataFactory.restaurant.open_tuesday:this.dataFactory.restaurant.deliver_tuesday;
      break;

      case 3:
        currentdaytimes = takeout?this.dataFactory.restaurant.open_wednesday:this.dataFactory.restaurant.deliver_wednesday;
      break;

      case 4:
        currentdaytimes = takeout?this.dataFactory.restaurant.open_thursday:this.dataFactory.restaurant.deliver_thursday;
      break;

      case 5:
        currentdaytimes = takeout?this.dataFactory.restaurant.open_friday:this.dataFactory.restaurant.deliver_friday;
      break;

      case 6:
        currentdaytimes = takeout?this.dataFactory.restaurant.open_saturday:this.dataFactory.restaurant.deliver_saturday;
      break;

    }


    let openinghour = now.clone();
    let closinghour = now.clone();

    currentdaytimes.split(",", 3).forEach(openingclosinghour => {
      if(openingclosinghour.length!=11){
        return false;
      }

      const first = openinghour.hour(+(openingclosinghour.substring(0, 2))).minute(+(openingclosinghour.substring(3, 5))).second(0);
      const indexDash = openingclosinghour.search("-");
      let last = closinghour.hour(+(openingclosinghour.substring(indexDash + 1, indexDash + 3))).minute(+(openingclosinghour.substring(indexDash + 4, indexDash + 6))).second(0);

      if (last.isBefore(first)) {
        last = last.add(1, "day");
      }
  
      hours.push(first);
      hours.push(last);
  
    });
  
  }


  public getCustomTitleForDeliveryOptions(): string {
    if (this.dataFactory.restaurant.isDeliveringToday == 0) {
      this._translateService.get('checkout.only-afhaal-label').subscribe((translated: string) => {
        return translated;
      });
    }
    return "";
  }

  public changeDeliverZip(event) {
    if (this.dataFactory.deliverZipCodes.length != 0) {
      let foundZip: DeliverZipCode = new DeliverZipCode();

      if (event != null) {
        foundZip = this.dataFactory.deliverZipCodes.find(zip=>zip.zcZip == event);
      } else {
        foundZip = this.dataFactory.deliverZipCodes.find(zip=>zip.zcZip == this.newOrder.deliverZip);
      }

      if (foundZip != null) {
        this.newOrder.deliverZip = foundZip.zcZip;
				const pivot = foundZip.pivot;
        this.newOrder.deliveryPrice = +(pivot.delivery_charge);

				if ((+(pivot.free_above) > 0) && (this.newOrder.subtotalPrice >= +(pivot.free_above))) {
					this.newOrder.deliveryPrice = 0;
				}

        this.newOrder.totalPrice = +(this.newOrder.subtotalPrice) + +(this.newOrder.deliveryPrice) + this.newOrder.transactionPrice;

        if (this.newOrder.subtotalPrice < +(foundZip.pivot.minimum_order)) {
          // this.basketFactory.isMinimumPriceReached = false;
          this.errorMessages = [];
          this._translateService.get('checkout.min-order-label', { bedrag: foundZip.pivot.minimum_order }).subscribe((translated: string) => {
            let errormessage: string = translated;
            this.errorMessages.push(errormessage);
            this.sendFormErrorToGoogleAnalytics(this.dataFactory.restaurant.id, errormessage);
          });

        } else {
          this.errorMessages = [];
          // this.basketFactory.isMinimumPriceReached = true;
          // if (+(pivot.free_above) > 0) {
          //   this.basketErrorMessage = "U kunt gratis laten bezorgen als u boven € " + foundZip.pivot.free_above + ".00 besteld."
          // }
          // if (this.basketFactory.subtotalPrice >= +(foundZip.pivot.free_above)) {
          //   this.basketFactory.setPrices(-1, 0);
          //   this.basketErrorMessage = "U kunt gratis laten bezorgen."
          // }
        }


        this.fillContantPaymentAmountArray();
      }
    }
  }

  public getCustomPaymentInfo() {
    this.transactionCostText = "";

    if (this.selectedPaymentMethod != null) {
      let transCost = this.calculateTransactionCost(this.selectedPaymentMethod);
      this.newOrder.transactionPrice = +(transCost);
      transCost = transCost.replace('.', ',');
      if (this.selectedPaymentMethod.pmStrId != "CNT") {
        //online payment
        if (this.newOrder.transactionPrice > 0) {
          this._translateService.get('checkout.transaction1-label', { transName: this.selectedPaymentMethod.pmName, transCost: transCost }).subscribe((translated: string) => {
            this.transactionCostText = translated;
          });
        } else {
          this._translateService.get('checkout.transaction2-label', { transName: this.selectedPaymentMethod.pmName }).subscribe((translated: string) => {
            this.transactionCostText = translated;
          });
        };
      }
    }
    this.updateTotalPrice();
  }

	public calculateSubForToPriceOrPrice() {
		let subtotalPrice = 0;
		for (let i = 0; i < this.newOrder.productList.length; i++) {
			const element = this.newOrder.productList[i];
			let calcTot = 0;
			if(this.newOrder.isPickup) {
				calcTot += +(element.to_menu_price);
			} else {
				calcTot += +(element.menu_price);
			}

			// if(element.childs != null) {
			// 	for (let j = 0; j < element.childs.length; j++) {
			// 		const elem = element.childs[j];
			// 		calcTot += +(elem.child_price);
			// 	}
			// }

      //this is calculated in 

			subtotalPrice += +(calcTot * +(element.qty));
		}

		this.newOrder.subtotal_before = Math.round((subtotalPrice + Number.EPSILON)*100 )/100;
		let discountC = this.newOrder.subtotal_before * (+(this.newOrder.discountP) / 100);
		this.newOrder.discountC = Math.round(discountC * 100) / 100;
		this.newOrder.subtotalPrice = Math.round(((this.newOrder.subtotal_before - this.newOrder.discountC) + Number.EPSILON)*100 )/100;
	}

  public changeDeliveryType() {
		this.calculateSubForToPriceOrPrice();
    if (this.newOrder.isPickup) {
      this.newOrder.totalPrice = +(this.newOrder.subtotalPrice) + +(this.newOrder.transactionPrice);
      this.newOrder.deliveryPrice = 0;
      this.fillContantPaymentAmountArray();

    } else {
      this.changeDeliverZip(this.newOrder.deliverZip);
    }
    this.fillDeliveryTimeArray();
    this.getCustomPaymentInfo();
  }

  public fillContantPaymentAmountArray() {
    this.betalingsmogelijkheden(this.newOrder.totalPrice);
    this.newOrder.contantPaymentAmount = 0;
    // this.newOrder.contantPaymentAmount = +(this.newOrder.totalPrice);
  }


  isEmail(search: string): boolean {
    var serchfind: boolean;

    let regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

    serchfind = regexp.test(search);

    //console.log(serchfind)
    return serchfind
  }


  private interncreateOrder(checkerror: boolean): boolean {
    var ret: boolean = false;
    this.errorMessages = [];


    if (checkerror) {

      this.newOrder.buyerEmail = this.newOrder.buyerEmail.split(' ').join('').trim();

      if (!this.isEmail(this.newOrder.buyerEmail)) {

        this._translateService.get('checkout.email-fout-label').subscribe((translated: string) => {
          let errormessage: string = translated;
          this.errorMessages.push(errormessage);
          this.sendFormErrorToGoogleAnalytics(this.dataFactory.restaurant.id, errormessage);
          ret = true;
        });
      }

      if (this.newOrder.deliveryHour == "select") {

        this._translateService.get('checkout.tijd-fout-label').subscribe((translated: string) => {
          let errormessage: string = translated;
          this.errorMessages.push(errormessage);
          this.sendFormErrorToGoogleAnalytics(this.dataFactory.restaurant.id, errormessage);
          ret = true;
        });

      }

      if ((this.dataFactory.restaurant.isOpenToday == 0)) {
        this.sendFormErrorToGoogleAnalytics(this.dataFactory.restaurant.id, "Restaurant is gesloten");
        ret = true;
      }

      if (Math.round(((this.newOrder.subtotal_before - this.newOrder.discountC) + Number.EPSILON)*100 )/100 != this.newOrder.subtotalPrice) {

        this._translateService.get('checkout.korting-fout-label').subscribe((translated: string) => {
          let errormessage: string = translated;
          this.errorMessages.push(errormessage);
          this.sendFormErrorToGoogleAnalytics(this.dataFactory.restaurant.id, errormessage);
          ret = true;
        });

      }
    }
    else {
      //place order

      if (this.errorMessages.length <= 0) {
        this.newOrder.paymentMethod = this.selectedPaymentMethod.pmStrId;
        this.errorMessages = [];
        this.newOrder.browser = navigator.userAgent;
        this.newOrder.checkoutreferrer = this.dataFactory.checkoutreferrer;

        if (!this.newOrder.isPickup) {
          this._loaderService.show();

          this.getDistance(this.dataFactory.restaurant.restaurant_address, this.dataFactory.restaurant.restaurant_city, this.dataFactory.restaurant.restaurant_postcode,
            this.newOrder.deliverAddress, this.newOrder.deliverCity, this.newOrder.deliverZip);

          let totalWaitMilliSeconds: number = 0;

          let distanceInterval = setInterval(() => {
            if (totalWaitMilliSeconds == 2000 || this.newOrder.distance != "0") {
              this.placeOrder();
              clearInterval(distanceInterval)
            }
            totalWaitMilliSeconds += 100;
          }, 100)
        } else {
          this.placeOrder();
        }
      }

    }



    return ret;
  }


  public createOrder() {

    if (!this.interncreateOrder(true)) //indien geen fout dan effectief plaatsen
    {
      this.interncreateOrder(false);
    }

  }

  public calculateItemPrice(qty: number, item_price: number): Number {
    let itemPriceTotal: number = 0;

    itemPriceTotal += +(item_price);

    return +(qty) * +(itemPriceTotal);
  }

  public generateExtrasTextBasketItem(extras: BasketListChild[]): string {
    let result: string = "";

    if (extras != null) {
      extras.forEach(element => {
        result = result + element.child_name + ", ";
      });
      result = result.slice(0, result.length - 2);
    }

    return result
  }

  private updateTotalPrice() {
    this.newOrder.totalPrice = ((+(this.newOrder.subtotalPrice)) + (+(this.newOrder.deliveryPrice)) + (+(this.newOrder.transactionPrice)));
  }

  private calculateTransactionCost(paymentMethod: PaymentMethod): string {
    let price: string = "";
    if (/^\d+(\.\d+)?%$/.test(paymentMethod.pmCost)) {

      let total: number = +((+(this.newOrder.subtotalPrice) + (+(this.newOrder.deliveryPrice))))
      price = new DecimalPipe("en-US").transform(total * (parseFloat(paymentMethod.pmCost) / 100), '1.2-2');
    } else {
      price = new DecimalPipe("en-US").transform(paymentMethod.pmCost, '1.2-2');
    }

    return price;
  }

 
  private placeOrder() {
    this._dataService.placeOrder(this.newOrder).subscribe(res => {
      if (res.success == 0 || res.success == false) {

        if (res.productMismatch || res.productMissing) {
          if (res.productMismatch) {
            res.productMismatches.forEach(element => {
              let foundProduct = this.newOrder.productList.find(x => x.menu_id == element.rdMnuId);
              if (foundProduct != null) {
                if (element.childs != undefined) {
                  element.childs.forEach(element => {
                    let foundChild = foundProduct.childs.find(x => x.id == element.rcgcId);
                    if (foundChild != null) {
                      foundChild.child_price = element.rcPrice;
                    }
                  });
                }
                foundProduct.qty = element.rdQty;
                foundProduct.menu_price = element.rdMnuPrice;
                foundProduct.to_menu_price = element.rdMnuToPrice;


              }
            });
          }

          if (res.productMissing) {
            res.productMissings.forEach(element => {
              let foundProductIndex = this.newOrder.productList.findIndex(x => x.menu_id == element);
              if (foundProductIndex >= 0) {
                this.newOrder.productList.splice(foundProductIndex, 1);
              }
            });
          }


          let subtotalBefore: number = 0;

          this.newOrder.productList.forEach( (val) => {
            subtotalBefore = subtotalBefore + (this.newOrder.isPickup ? (+(val.to_menu_price) * +(val.qty)) : (+(val.menu_price) * +(val.qty)));
          });

          this.newOrder.subtotal_before = subtotalBefore;
          let discountC = this.newOrder.subtotal_before * (+(this.newOrder.discountP) / 100);
          this.newOrder.discountC = Math.round(discountC * 100) / 100;

          this.newOrder.subtotalPrice = +(this.newOrder.subtotal_before) - +(this.newOrder.discountC);

          this.newOrder.setPrices(this.newOrder.subtotalPrice, this.newOrder.deliveryPrice, this.newOrder.subtotal_before);
          this.fillContantPaymentAmountArray();
					this.changeDeliverZip(null);
          this.errorMessages = [];
          this._translateService.get('checkout.wijzigingen-menu').subscribe((translated: string) => {
            this.errorMessages.push(translated);
          });

        } else {


          for (let errorkey in res.value) {
            let errormessage: string = res.value[errorkey];
            this.errorMessages.push(errormessage);
            this.sendFormErrorToGoogleAnalytics(this.dataFactory.restaurant.id, errormessage);
            // for (let value of res.value[errorkey]) {

            // }
          }
        }
      } else {
        this.sendOrderEventToGoogleAnalytics(this.newOrder);

        let userStorage: UserFormStorage = new UserFormStorage()
        userStorage.mapOrderFormToUserFormStorage(this.newOrder);
        let expiredDate = new Date();
        expiredDate.setDate(expiredDate.getDate() + 365);
        this._cookieService.set('userform', JSON.stringify(userStorage), expiredDate, '/');

        window.location.href = res.url + this.clientid(res.url.includes('?') ? true : false);
      }
    });
  }

  private clientid(already: boolean) {
    let ret = '';

    try {

      if ((<any>window).ga != undefined) {
        ret = (already ? '&' : '?') + (<any>window).ga.getAll()[0].get('linkerParam');
      }
    }
    catch (Error) {
      this.sendGaclientIdErrorToGoogleAnalytics('get_clientId_error');
      //alert(Error.message);
    }
    finally {
      return ret;
    }



  }

  private sendOrderEventToGoogleAnalytics(order: OrderForm) {
    let gaCategory: string = "newOrder_" + this.dataFactory.restaurant.restaurant_name + "_";

    if (order.isPickup) {
      gaCategory += this.dataFactory.restaurant.restaurant_postcode;
    } else {
      gaCategory += order.deliverZip;
    }

    gaCategory += "_" + order.totalPrice;

    this._dataService.sendGoogleAnalyticsEvent(gaCategory, order.totalPrice.toString(), "", -1);
  }

  private sendGaclientIdErrorToGoogleAnalytics(gaLabel: string) {
    this._dataService.sendGoogleAnalyticsEvent("NotFound", "clientId", gaLabel, -1);
  }

  private sendDistanceErrorToGoogleAnalytics(gaLabel: string) {
    this._dataService.sendGoogleAnalyticsEvent("NotFound", "AddressDistance", gaLabel, -1);
  }

  private sendFormErrorToGoogleAnalytics(gaAction: string, gaLabel: string) {
    this._dataService.sendGoogleAnalyticsEvent("ErrorAfronden", gaAction, gaLabel, -1);
  }

  private splitOpeningshoursOfToday() {
    if (this.dataFactory.restaurant.denemeopen2 != "") {
      const arr: string[] = this.dataFactory.restaurant.denemeopen2.split(';');

      if (arr.length > 0) {
        switch (arr[0].toLowerCase()) {
          case "closedallday":
            this.dataFactory.restaurant.isOpenToday = 0;
            this.dataFactory.restaurant.isDeliveringToday = 0;
            break;
          case "open":
            this.dataFactory.restaurant.isOpenToday = 1;
            this.dataFactory.restaurant.isDeliveringToday = this.splitDeliveryhoursOfToday(arr[1]);
            break;
          case "close":
            this.dataFactory.restaurant.isOpenToday = 2;
            this.dataFactory.restaurant.isDeliveringToday = this.splitDeliveryhoursOfToday(arr[1]);
            break;
          default:
            this.dataFactory.restaurant.isOpenToday = 1;
            this.dataFactory.restaurant.isDeliveringToday = 1;
            break;
        }
      }
    }
  }


  private splitDeliveryhoursOfToday(deliveryToday: string): number {
    switch (deliveryToday.toLowerCase()) {
      case "closedallday":
        return 0;
      case "open":
        return 1;
      case "close":
        return 2;
      default:
        return 1;
    }
  }

  private betalingsmogelijkheden(baskettotal: number) {
    let betaalmunten: number[] = [1, 2, 5, 10, 20, 50, 100, 200, 500]; //euro munten

    this.betaalmogelijkheden = [];

    let foundit = -1;

    for (let i = 0; i < betaalmunten.length; i++) {
      if (baskettotal <= betaalmunten[i]) {
        foundit = i;
        for (let j = 0; betaalmunten.length; j++) {
          if (i == j)
            break;

          if (i > 0) {
            if ((betaalmunten[i - 1] + betaalmunten[j]) > baskettotal) {
              if (!this.betaalmogelijkheden.includes(betaalmunten[i - 1] + betaalmunten[j]))
                this.betaalmogelijkheden.push(betaalmunten[i - 1] + betaalmunten[j]);
            }
            else {
              if (j > 0)
                this.test_betaalmunten(betaalmunten, i, j, baskettotal);
            }
          }
        }
        break;
      }
    }

    if (foundit > 0 && foundit < 8) {
      for (let i = 0; i < 6; i++) {
        if (baskettotal <= betaalmunten[i])
          if (!this.betaalmogelijkheden.includes(betaalmunten[i]))
            this.betaalmogelijkheden.push(betaalmunten[i]);;
      }
    }
  }

  private test_betaalmunten(betaalmunten, i, j, total) {
    //alle mogelijkheden checken voor de $i

    let totalcalc = 0;

    for (let k = 0; k <= j; k++) {
      totalcalc = betaalmunten[i - 1] + betaalmunten[k];

      let l = 1;
      while (totalcalc < total) {
        l++;

        totalcalc = betaalmunten[i - 1] + (l * betaalmunten[k]);
      }

      if ((totalcalc % betaalmunten[k]) == 0) {
        if (!this.betaalmogelijkheden.includes(totalcalc))
          this.betaalmogelijkheden.push(totalcalc);
      }

      if (k > 100)
        break;
    }
  }

  // private subscribeToGetAllPaymentMethods() {
  //   this._dataService.getPaymentMethods().subscribe(res => {
  //     this.dataFactory.paymentMethods = res;

  //     if(this.dataFactory.restaurant.onlyonlinepay)
  //     {
  //       this.dataFactory.paymentMethods = this.dataFactory.paymentMethods.filter(obj => obj.id !== 1);
  //     }


  //     this.dataFactory.paymentMethods.forEach(element => {
  //       switch (element.id) {
  //         case 1: element.imgUrl = "../../assets/img/payment_cnt.png";
  //           this.calculatePercentNumberForPaymentMethods(element);
  //           break;
  //         case 2: element.imgUrl = "../../assets/img/payment_bk.png";
  //           this.calculatePercentNumberForPaymentMethods(element);
  //           break;
  //         case 3: element.imgUrl = "../../assets/img/payment_vs.png";
  //           this.calculatePercentNumberForPaymentMethods(element);
  //           break;
  //         case 4: element.imgUrl = "../../assets/img/payment_mc.png";
  //           this.calculatePercentNumberForPaymentMethods(element);
  //           break;
  //         case 5: element.imgUrl = "../../assets/img/payment_ae.png"
  //           this.calculatePercentNumberForPaymentMethods(element);
  //           break;
  //         case 6: element.imgUrl = "../../assets/img/payment_pp.png";
  //           this.calculatePercentNumberForPaymentMethods(element);
  //           break;
  //       }
  //     });

  //     this.selectedPaymentMethod = this.dataFactory.paymentMethods[0];
  //   });
  // }

  private subscribeToGetProductById() {
    this._ActivatedRoute.params.subscribe(params => {
      var regexGuid = /^(\{){0,1}[0-9a-fA-F]{8}[0-9a-fA-F]{4}[0-9a-fA-F]{4}[0-9a-fA-F]{4}[0-9a-fA-F]{12}(\}){0,1}$/gi;
      this.dataFactory.orderGuid = params["id"];

      if (regexGuid.test(this.dataFactory.orderGuid)) {

        this._dataService.getOrderDetails(this.dataFactory.orderGuid).subscribe(res => {
          // console.log("------------------------------------")
          // console.log(res);
          if (res.success == 1) {
            if(res.data.country_id==SharedDataService.countryid){

              var lang = res.data.lang;
              var ttt="en/ordered-at/";
              if (lang=="fr"||lang=="nl"||lang=="en"){
                this._translateService.use(res.data.lang);
                if(lang=="nl"){
                  ttt="nl/besteld-bij/";
                }
                else if(lang=="fr"){
                  ttt="fr/commande-aupres-de/";
                }
              }
  
              if (res.data.placed == "1") {
                //console.log("order is al geplaatst");
                // window.location.href = res.data.checkoutreferrer + "/besteld-bij/" + this.dataFactory.restaurant.id + "/" + res.data.rest_slug + "/" + res.data.guid;
                window.location.href = res.data.checkoutreferrer + ttt + res.data.rest_slug + "/" + res.data.guid;
              } else {
                //console.log("order is nieuw")
              }
  
              this.mapOrderDataToCorrectVariables(res.data);
              this.newOrder.productList = res.data.details;
              // checken => als deliverzipcodes == [] -> zoeken naar deliver zipcodes
  
              // openingsuren & adresgegevens opzoeken voor in de footer
  
  
              this.fillContantPaymentAmountArray();
  
              this._dataService.getRestaurantInfo(this.dataFactory.restaurant.id).subscribe(result => {
  
                let x = this.dataFactory.restaurant.servertime;
  
  
                // console.log("x: "+x);
  
  
                this.dataFactory.restaurant = result;
                if(result.servertime==undefined)
                {
                  // console.log("undefined");
                  this.dataFactory.restaurant.servertime = x;
                }
                 //console.log(this.dataFactory.restaurant.servertime);
                this.dataFactory.paymentMethods = result.paymentmethods;
  
                this.dataFactory.restaurant.restaurant_name = this.tcp.transform(this.dataFactory.restaurant.restaurant_name);
                this.dataFactory.restaurant.restaurant_address = this.tcp.transform(this.dataFactory.restaurant.restaurant_address);
                this.dataFactory.restaurant.restaurant_city = this.tcp.transform(this.dataFactory.restaurant.restaurant_city);
  
  
                this.dataFactory.restaurant.calc_title =  this.dataFactory.restaurant.restaurant_name +   ((this.dataFactory.restaurant.restaurant_name.indexOf(this.dataFactory.restaurant.restaurant_city) !== -1) ?"":(" " + this.dataFactory.restaurant.restaurant_city));
  
  
                if (this.dataFactory.restaurant.onlyonlinepay) {
                  this.dataFactory.paymentMethods = this.dataFactory.paymentMethods.filter(obj => obj.id !== 1);
                }
  
  
                this.dataFactory.paymentMethods.forEach(element => {
                  element.imgUrl = environment.CDN_URL+"static/images/paymethods/payment_" + element.pmStrId.toLowerCase() + ".png";
                  //element.imgUrl = "../../assets/img/payment_" + element.pmStrId.toLowerCase() + ".png";
                });
  
                this.selectedPaymentMethod = this.dataFactory.paymentMethods[0];
  
  
  
                // if(this.dataFactory.restaurant.onlyonlinepay)
                // {
                //   this.changePaymentMethod(this.dataFactory.paymentMethods[1]);
                //   this.dataFactory.paymentMethods = this.dataFactory.paymentMethods.filter(obj => obj.id !== 1);
                //   this.changePaymentMethod(this.dataFactory.paymentMethods[0]);
                // }
  
                this.splitOpeningshoursOfToday();
  
                // this.mapOrderDataToCorrectVariables(res.data); <-- already called in
                this.fillDeliveryTimeArray();
                clearInterval(this.callInterval);
  
                if (this.dataFactory.restaurant.isDeliveringToday == 0) {
                  this.newOrder.isPickup = true;
                  this.changeDeliveryType();
                }
                this._dataService.restoreaded.emit();
              });
  
              if (this.dataFactory.restaurant.restaurant_postcode != undefined) {
  
                this._translateService.get('checkout.bestelling-afronden-titel').subscribe((translated: string) => {
                  this.setTitle(translated + " " + res.data.rest_name + " " + this.dataFactory.restaurant.restaurant_postcode + " " + this.dataFactory.restaurant.restaurant_city);
                });
  
              } else {
  
                this._translateService.get('checkout.bestelling-afronden-titel').subscribe((translated: string) => {
                  this.setTitle(translated + " " + res.data.rest_name);
                });
  
              }
            }
            else{
              //countryid is not same 
              this._router.navigate(["/"]);
            }
            //console.log("ontvangen data")
            //console.log(res);


          } else {

            this._translateService.get('checkout.data-not-found-label').subscribe((translated: string) => {
              window.alert(translated);
              this._router.navigate(["/"]);
            });
          }
        }, err => {
          this._translateService.get('checkout.unexp-error-label').subscribe((translated: string) => {
            window.alert(translated);
            this._router.navigate(["/"]);
          });
        });
      } else {
        this._router.navigate(["/"]);
      }
    });
  }

  private mapOrderDataToCorrectVariables(item: OrderData) {
    this.dataFactory.setRestaurantDefaultData(item.rest_id, item.rest_name, item.rest_slogan, item.rest_logo, item.rest_subimg);
    this.dataFactory.checkoutreferrer = item.checkoutreferrer;
    this.dataFactory.deliverZipCodes = item.restaurant_delivercodes;

    this.distinctDeliverZipCodes = item.restaurant_delivercodes.filter((a, i) => item.restaurant_delivercodes.findIndex((s) => a.zcZip === s.zcZip) === i);
    this.distinctDeliverZipCodes.sort(function (obj1, obj2) {
      // Ascending: first age less than the previous
      return obj1.zcZip - obj2.zcZip;
    });
    //console.log(this.distinctDeliverZipCodes);
    this.newOrder.referrer = item.referrer;

    this.newOrder.contantPaymentAmount = 0;
    this.newOrder.setDiscount(item.discountP, item.discountC);
    // this.newOrder.contantPaymentAmount = +(item.totalprice);
    this.newOrder.deliverZip = item.zipcode;
    this.newOrder.setPickupMethod(item.pickup);
    this.newOrder.setPrices(item.subtotal, item.deliveryprice, item.subtotal_before);

    if (this.newOrder.isPickup) {
      this.newOrder.deliverZip = "";
    }
  }

  private setTitle(newTitle: string) {
    this._titleService.setTitle(newTitle);
  }

  private getDistance(resStreet: string, resCity: string, resZip: string, userStreet: string, userCity: string, userZip: string) {

    if (typeof google === 'object' && typeof google.maps === 'object'){
      var service = new google.maps.DistanceMatrixService();
      service.getDistanceMatrix(
        {
          origins: [(SharedDataService.countryid==2?"nederland":"belgie") + "+" + resZip + "+" + resCity + "+" + resStreet],
          destinations: [(SharedDataService.countryid==2?"nederland":"belgie")+ "+" + userZip + "+" + userCity + "+" + userStreet],
          travelMode: 'DRIVING',
          avoidHighways: false,
          avoidTolls: false,
        }, (response, status) => {
          if (status == google.maps.DistanceMatrixStatus.OK) {
            this.newOrder.distance = response['rows'][0]['elements'][0]['distance']['text'];
          }
          else {
            this.sendDistanceErrorToGoogleAnalytics(response['rows'][0]['elements'][0]['distance']['text']);
          }
        });
    }

  }

  private checkaddressnumber(address: string)
  {
		this.erroraddressnumber = '' != address && (null == address.match(/\d+/g) ? true : false);
  }
}
